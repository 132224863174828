import { useEffect, useState } from "react";
import "./Preloader.scss";
const Preloader = (props) => {

  const { audio, 
    percentageLoaded, 
    setIsMuted, 
    entered, 
    setEntered,
    overallIndex,
    setOverallIndex
  } = props;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (percentageLoaded === 100) {
      setReady(true);
    }
  }, [percentageLoaded]);

  const handleClick = () => {
    setEntered(true);
    setIsMuted(false);
    setOverallIndex(0);
    audio.current.play();
  };

  useEffect(()=>{
    // console.log(overallIndex);
  },[overallIndex])
  return (
    <div className={`preloader__wrapper ${entered ? "disabled" : ""}`}>
      <div className="preloader__inner">
        <img
          src="./assets/images/logo.png"
          alt="MASHA POPOVA"
          className="preloader__logo"
        />
        <h1 className="preloader__title">MONSTER</h1>
        <div className={`preloader__load-outer ${ready ? "disabled" : ""}`}>
          <div
            className="preloader__load-inner"
            style={{ width: `${percentageLoaded}%` }}
          ></div>
          <div className="preloader__load-text">{Math.round(percentageLoaded)}%</div>
        </div>
        <button
          onClick={handleClick}
          className={`preloader__enter-button ${ready ? "active" : ""}`}
          // className={`preloader__enter-button active`}

        >
          ENTER
        </button>
      </div>
    </div>
  );
};

export default Preloader;
