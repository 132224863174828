import { useState } from "react";
import { degToRad } from "three/src/math/MathUtils";
import VideoPlane from "./VideoPlane";

const Walls = () => {

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = "./assets/textures/walls/vid_01.mp4";
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.play();
    return vid;
  });


  return (
    <>
      <VideoPlane
        video={video}
        position={[0, 62.5, -175]}
        rotation={[0, 0, 0]}
        width={300}
        height={175}
      />
      <VideoPlane
        video={video}
        position={[-175, 62.5, -0]}
        rotation={[0, degToRad(90), 0]}
        width={300}
        height={175}
      />
      <VideoPlane
        video={video}
        position={[175, 62.5, -0]}
        rotation={[0, degToRad(-90), 0]}
        width={300}
        height={175}
      />

    </>
  );
};

export default Walls;
