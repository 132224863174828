import { useState, useEffect } from "react";
import { DoubleSide } from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

const MobileModels = (props) => {
  const { model, overallIndex } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [images] = useState([
    {
      image: useLoader(TextureLoader, `${model.path}01.png`),
      position: [0, 22.5, -170],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}02.png`),
      position: [0, 22.5, -138],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}03.png`),
      position: [0, 22.5, -104],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}04.png`),
      position: [0, 22.5, -72],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}05.png`),
      position: [0, 22.5, -40],
      opacity: 0,
    },
  ]);

  useEffect(() => {
    if (model.number === overallIndex) {
      setIsActive(true);
      model.isActive = true;
    } else {
      setIsActive(false);
      model.isActive = false;
      images.forEach((image) => {
        image.opacity = 0;
      });
      setProgress(0);
      setCurrentIndex(0);
    }
  }, [overallIndex ]);

  useFrame(() => {
    if (isActive) {
      if (progress < 1) {
        setProgress((prevProgress) => Math.min(prevProgress + 0.05, 1.0));
      } else {
        handleTransition();
      }
      images.forEach((image) => {
        if (image !== images[currentIndex]) {
          if (image.opacity > 0) {
            image.opacity -= 0.05;
          }
        }
        images[currentIndex].opacity = progress;
      });
    } 
  });


  const handleTransition = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setProgress(0);
    }
  };

  if(model.isActive){
  return (
    <>
      {images &&
        images.map((image, index) => {
          return (
            <mesh
              position={image.position}
              key={index}
            >
              <meshBasicMaterial
                map={image.image}
                side={DoubleSide}
                transparent={true}
                opacity={image.opacity}
              />
              <planeGeometry args={[50, 50, 50, 50]} />
            </mesh>
          );
        })}
    </>
  );}
};

export default MobileModels;
