import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import LoadingManager from "../global/LoadingManager";
import { degToRad } from "three/src/math/MathUtils";

import MobileFloor from "./floor/MobileFloor";
import Lights from "../Lights";
import MobileWalls from "./walls/MobileWalls";
import MobileTalent from "./talent/MobileTalent";

const MobileScene = (props) => {
    const { setPercentageLoaded, overallIndex, isMobile } = props;

  return (
    <div style={{ width: "100%", height: "100vh" }} className="home__canvas">
      <Canvas flat>
        <Suspense
          fallback={
            <LoadingManager setPercentageLoaded={setPercentageLoaded} isMobile={isMobile}/>
          }
        >
          <OrbitControls
            target={[0, 10, 0]}
            minAzimuthAngle={degToRad(-10)}
            maxAzimuthAngle={degToRad(10)}
            minPolarAngle={degToRad(60)}
            maxPolarAngle={degToRad(112)}
            minZoom={10}
            maxZoom={10}
            enableZoom={false}
            enablePan={false}
            maxDistance={30}
          />
          <PerspectiveCamera position={[0, 8, 10]} makeDefault />
          <MobileFloor />
          <MobileWalls />
          <MobileTalent overallIndex={overallIndex}/>
          <Lights />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default MobileScene