const GlassBlackMaterial = () => {
  return (
    <meshStandardMaterial
    color={0x000000}
    transparent={true}
    opacity={0.8}
    metalness={1}
    roughness={0}
    />
  )
}

export default GlassBlackMaterial