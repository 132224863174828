import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client";

import BackButton from "../components/global/BackButton";
import Loading from "../components/global/Loading";
import LookItem from "../components/lookbook/LookItem";

import "./Lookbook.scss";

const Lookbook = () => {

  const [ pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "looks"]|order(_createdAt asc)`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if(pageData){
    return (
      <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="outer-wrapper"
    >
      <BackButton />
      <div className="looks__grid-wrapper">
      {pageData &&
        pageData.map((look, index)=>{
          return(
            <LookItem {...{
              look,
              index,
              // setCurrentImage,
            }} key={index}/>
          )
        })}
      </div>
      
    </motion.main>
    )
  } else {
    return <Loading />
  }

}

export default Lookbook