// import { useState} from 'react'

const VideoPlane = (props) => {
  const { position, rotation, width, height, video } = props;

  // const [video] = useState(() => {
  //   const vid = document.createElement("video");
  //   vid.src = videoSrc;
  //   vid.crossOrigin = "Anonymous";
  //   vid.loop = true;
  //   vid.muted = true;
  //   vid.play();
  //   return vid;
  // });

  return (
    <mesh rotation={rotation} position={position}>
        <planeGeometry args={[width, height, 1.9]} />
        <meshBasicMaterial color={0x6a5287}>
          <videoTexture attach="map" args={[video]}/>
        </meshBasicMaterial>
      </mesh>
  )
}

export default VideoPlane
