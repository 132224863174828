import { useProgress } from "@react-three/drei";
import { useEffect } from "react";
const LoadingManager = (props) => {
  const { setPercentageLoaded, isMobile } = props;
  const { loaded, total } = useProgress();
  useEffect(() => {
    // console.log(total, isMobile);
    if(isMobile){
      if ((loaded / 170) * 100 < 100) {
        setPercentageLoaded(Math.round((loaded / 170) * 100));
      } else {
        setPercentageLoaded(100);
      }
    } else {
      if ((loaded / 180) * 100 < 100) {
        setPercentageLoaded(Math.round((loaded / 180) * 100));
      } else {
        setPercentageLoaded(100);
      }
    }

  }, [loaded, total, isMobile, setPercentageLoaded]);
  return;
};

export default LoadingManager;
