import { DoubleSide } from "three";

const ImagePlane = (props) => {
  const { position, map, rotation, width, height, color } = props;
  return (
    <mesh 
    castShadow
    receiveShadow
    rotation={rotation} position={position}>
      <planeGeometry args={[width, height]} />
      <meshBasicMaterial 
      side={DoubleSide} 
      map={map?map:undefined}
      // alphaMap={alpha?alpha:undefined}
      color={color} 
      transparent
      />
    </mesh>
  );
};

export default ImagePlane;
