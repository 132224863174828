import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const LookItem = (props) => {

  const { image, index } = props;

  const smallUrl = `${urlFor(image)}?q=10`;
  const largeUrl = `${urlFor(image)}`;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <div className="zine__item-wrapper">
      <img
        src={src}
        alt={`MASHA POPOVA ZINE - ${index}`}
        className="zine__item-image"
        style={{
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out",
        }}
      />
    </div>
  );
};

export default LookItem;
