const BodyMaterial = (props) => {
  const { BodyMap, roughness } = props;

  return (
    <meshStandardMaterial
      map={BodyMap}
      roughness={0.2}
      metalness={0.9}
      bumpMap={roughness?roughness:null}
      bumpScale={0.1}
    />
  );
};

export default BodyMaterial;
