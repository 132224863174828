import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";

import BackButton from "../components/global/BackButton";
import Loading from "../components/global/Loading";

import "./Credits.scss";

const Credits = () => {

  const [ pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "credits"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if(pageData){
    return (
      <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="outer-wrapper"
    >
      <BackButton />
      <div className="credits__grid-wrapper">
      <PortableText value={pageData.credits} components={UnderlineLink} />
      </div>
    </motion.main>
    )
  } else {
    return <Loading />
  }

}

export default Credits