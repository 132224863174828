import { Suspense, useEffect, useState } from "react";
import LoadingManager from "../global/LoadingManager";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";

import Floor from "../floor/Floor";
import Talent from "../talent/Talent";
import Walls from "../walls/Walls";
import Trucks from "../truck/Trucks";
import Lights from "../Lights";

const Scene = (props) => {
  const {
    setPercentageLoaded,
    isMobile,
    overallIndex,
  } = props;

  const [hasClicked, setHasClicked] = useState(false);

    const handleClicked = () => {
         setHasClicked(true);
    }

  return (
    <div style={{ width: "100%", height: "100vh" }} className="home__canvas" onClick={handleClicked}>
            <div className={`header__click default-button ${hasClicked?'disabled':''}`}>
        CLICK AND DRAG TO EXPLORE
      </div>
      <Canvas flat>
        <Suspense
          fallback={
            <LoadingManager
              setPercentageLoaded={setPercentageLoaded}
              isMobile={isMobile}
            />
          }
        >
          <OrbitControls
            target={[-5, 10, 0]}
            minAzimuthAngle={degToRad(-80)}
            maxAzimuthAngle={degToRad(80)}
            minPolarAngle={degToRad(60)}
            maxPolarAngle={degToRad(100)}
            minZoom={10}
            maxZoom={10}
            maxDistance={30}
          />
          <PerspectiveCamera position={[0, 8, 10]} makeDefault />

          <Lights />
          <Trucks />
          <Talent
            {...{
              overallIndex,
            }}
          />
          <Floor />
          <Walls />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Scene;
