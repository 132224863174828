import { useGLTF } from "@react-three/drei";
import { 
  // useLoader, 
  useFrame } from "@react-three/fiber";
// import { TextureLoader, EquirectangularReflectionMapping } from "three";

import BodyMaterial from "./materials/BodyMaterial";
import ChromeMaterial from "./materials/ChromeMaterial";
import GlassBlackMaterial from "./materials/GlassBlackMaterial";
import GlassRedMaterial from "./materials/GlassRedMaterial";
import GlassOrangeMaterial from "./materials/GlassOrangeMaterial";
import MetalicGreyMaterial from "./materials/MetalicGreyMaterial";
import MetalicBlackMaterial from "./materials/MetalicBlackMaterial";
import RubberMaterial from "./materials/RubberMaterial";
import IronMaterial from "./materials/IronMaterial";
import { useRef } from "react";
import CylinderConcreteMaterial from "../floor/CylinderConcreteMaterial";
import LogoOne from "./LogoOne";

const Truck = (props) => {
  const { position, rotation, BodyMap, roughness } = props;
  const truckRef = useRef();
  const { nodes, materials } = useGLTF("./assets/models/Truck3.glb");

  // const ChromeHdri = useLoader(
  //   TextureLoader,
  //   "./assets/textures/truck/ChromeHdri.png"
  // );
  // const RubberGloss = useLoader(
  //   TextureLoader,
  //   "./assets/textures/truck/RubberSpecular.jpg"
  // );
  // ChromeHdri.mapping = EquirectangularReflectionMapping;

  useFrame(() => {
    if (truckRef.current) {
      truckRef.current.rotation.y += 0.003;
    }
  });

  return (
    // <Suspense
    //       fallback={
    //         <LoadingManager 
    //         // setPercentageLoaded={setPercentageLoaded} 
    //         />} >
    <group
      {...props}
      dispose={null}
      position={position}
      rotation={rotation}
      ref={truckRef}
    >
      <mesh position={[0, -26, 0]}>
        <CylinderConcreteMaterial />
        <cylinderGeometry args={[50, 50, 3, 64, 1]} />
      </mesh>
      <LogoOne />
      <group position={[-0.022, 1.368, 0.154]} scale={0.02}>
        <group position={[0.383, -632.043, -1581.845]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.wheels_rotate12.geometry}
            position={[21.847, -736.445, 1428.146]}
          >
            <MetalicGreyMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.wheels_rotate13.geometry}
            position={[-891.708, 0.001, 0.002]}
            rotation={[0, -1.571, 0]}
          >
            <MetalicGreyMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.wheels_rotate16.geometry}
            position={[959.3, 0.001, 0.002]}
            rotation={[0, -1.571, 0]}
          >
            <RubberMaterial BodyMap={BodyMap} 
            // RubberGloss={RubberGloss} 
            />
          </mesh>
        </group>
        <group position={[-0.002, -632.043, 1654.086]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.wheels_rotate08.geometry}
            position={[22.233, -736.445, -1807.785]}
          >
            <MetalicGreyMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.wheels_rotate09.geometry}
            position={[959.685, 0.001, -3235.929]}
            rotation={[0, -1.571, 0]}
          >
            <RubberMaterial BodyMap={BodyMap} 
            // RubberGloss={RubberGloss} 
            />
          </mesh>
        </group>
        <group position={[12.246, 827.508, -121.878]} rotation={[0, 1.571, 0]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Gl_fanaPer745.geometry}
            position={[-1793.405, -182.518, 837.84]}
          >
            <GlassBlackMaterial />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Glass834.geometry}
            position={[-1793.406, -182.518, 837.84]}
          >
            <GlassBlackMaterial />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.GlassZadFara_7765.geometry}
            position={[2282.563, -71.988, 726.394]}
          >
            <GlassRedMaterial />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Kuzov2.geometry}
            position={[-1793.405, -182.518, -839.624]}
            rotation={[0, 0, -Math.PI]}
            scale={-1}
          >
            <BodyMaterial BodyMap={BodyMap} roughness={roughness}/>
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Pered_674.geometry}
            position={[-1793.406, -182.518, 837.84]}
          >
            <ChromeMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Rama_453.geometry}
            position={[1.208, -745.792, 384.38]}
          >
            <MetalicBlackMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.RelefPovorotPered.geometry}
            position={[-1793.405, -182.518, 837.84]}
          >
            <GlassOrangeMaterial />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ReshotkaRadiatora.geometry}
            position={[-1800.71, -182.518, 837.84]}
          >
            <ChromeMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.VnutrFarPered_897.geometry}
            position={[-1793.406, -182.518, 837.84]}
          >
            <ChromeMaterial 
            // ChromeHdri={ChromeHdri} 
            />
          </mesh>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts00.geometry}
          position={[-891.325, -632.043, -1581.843]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts103.geometry}
          material={materials["Red_plast-steel"]}
          position={[376.681, 149.538, 1528.91]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts104.geometry}
          position={[346.897, 348.661, 1469.004]}
          rotation={[0, -1.571, 0]}
        >
          <BodyMaterial BodyMap={BodyMap} roughness={roughness}/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts106.geometry}
          position={[-408.347, 158.635, 1521.884]}
          rotation={[0, -1.571, 0]}
        >
          <ChromeMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts108.geometry}
          position={[-360.884, 344.911, 1468.725]}
          rotation={[0, -1.571, 0]}
        >
          <BodyMaterial BodyMap={BodyMap} roughness={roughness}/>
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts110.geometry}
          position={[-300.682, 523.469, 1416.301]}
          rotation={[0, -1.571, 0]}
        >
          <IronMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts114.geometry}
          position={[7.081, -135.511, 1031.843]}
          rotation={[0, -1.571, 0]}
        >
          <IronMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts117.geometry}
          position={[7.081, -223.612, 1234.814]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts118.geometry}
          position={[425.717, 132.419, 1501.949]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts134.geometry}
          position={[606.851, -574.981, 1615.644]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts137.geometry}
          position={[-411.583, -227.977, -1406.952]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts144.geometry}
          position={[244.401, 648.4, -1754.408]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts162.geometry}
          position={[-6.905, -559.728, -1496.728]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts170.geometry}
          position={[-479.211, -18.841, -1285.213]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts174.geometry}
          position={[-479.211, -147.511, -1360.38]}
          rotation={[0, -1.571, 0]}
        >
          <IronMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts31.geometry}
          position={[822.656, 867.142, -95.901]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts38.geometry}
          position={[611.588, 312.102, -1650.135]}
          rotation={[0, -1.571, 0]}
        >
          <ChromeMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts51.geometry}
          position={[221.712, -239.241, 484.893]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts53.geometry}
          position={[-225.33, 322.603, -743.621]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts59.geometry}
          position={[6.758, 671.068, -1227.28]}
          rotation={[0, -1.571, 0]}
        >
          <IronMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts62.geometry}
          position={[117.594, 418.363, -1326.872]}
          rotation={[0, -1.571, 0]}
        >
          <IronMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts63.geometry}
          position={[117.594, 418.363, -1326.872]}
          rotation={[0, -1.571, 0]}
        >
          <ChromeMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts74.geometry}
          position={[293.887, -630.816, 1578.835]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts77.geometry}
          position={[-49.69, -518.957, 1677.156]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts85.geometry}
          position={[-249.671, -629.852, 1679.196]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.truck_parts90.geometry}
          position={[11.307, -276.535, -937.581]}
          rotation={[0, -1.571, 0]}
        >
          <MetalicBlackMaterial 
          // ChromeHdri={ChromeHdri} 
          />
        </mesh>
      </group>
    </group>
    // </Suspense>
  );
};

export default Truck;
useGLTF.preload("./assets/models/Truck3.glb");
