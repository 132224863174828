import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";

import Home from "./views/Home";
import Lookbook from "./views/Lookbook";
import Credits from "./views/Credits";
import Zine from "./views/Zine";

import Preloader from "./components/global/Preloader";
import Header from "./components/global/Header";
import Scene from "./components/global/Scene";
import MobileScene from "./components/mobile/MobileScene";

import "./styles/global-styles.scss";

function App() {
  const location = useLocation();
  const audio = useRef();
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [entered, setEntered] = useState(false);
  const [overallIndex, setOverallIndex] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 750) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  return (
    <>
      <Analytics />
      <Preloader
        {...{
          percentageLoaded,
          setIsMuted,
          audio,
          entered,
          overallIndex,
          setOverallIndex,
          setEntered,
          isMobile,
        }}
      />
      <Header
        {...{
          isMuted,
          setIsMuted,
          audio,
          overallIndex,
          setOverallIndex,
        }}
      />
      {isMobile ? (
        <MobileScene
          {...{
            setPercentageLoaded,
            overallIndex,
            isMobile,
          }}
        />
      ) : (
        <Scene
          {...{
            setPercentageLoaded,
            overallIndex,
            isMobile,
          }}
        />
      )}
      <Routes location={location} key={location.pathname}>
        <Route element={<Home key={"home"} />} path="/" />
        <Route element={<Lookbook key={"lookbook"} />} path="/lookbook" />
        <Route element={<Zine key={"zine"} />} path="/zine" />
        <Route element={<Credits key={"credits"} />} path="/credits" />
      </Routes>
    </>
  );
}

export default App;
