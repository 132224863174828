import { useLoader } from "@react-three/fiber";
import { TextureLoader, RepeatWrapping } from "three";
import { degToRad } from "three/src/math/MathUtils";

import Truck from "./Truck";

const Trucks = () => {
  const BodyMap1 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/03.jpg"
  );
  BodyMap1.wrapS = RepeatWrapping;
  BodyMap1.wrapT = RepeatWrapping;
  BodyMap1.repeat.x = 1;
  BodyMap1.repeat.y = -1;

  const roughness1 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/03.jpg"
  );
  roughness1.wrapS = RepeatWrapping;
  roughness1.wrapT = RepeatWrapping;
  roughness1.repeat.x = 1;
  roughness1.repeat.y = -1;

  const BodyMap2 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/04.jpg"
  );
  BodyMap2.wrapS = RepeatWrapping;
  BodyMap2.wrapT = RepeatWrapping;
  BodyMap2.repeat.x = 1;
  BodyMap2.repeat.y = -1;

  const roughness2 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/04.jpg"
  );
  roughness2.wrapS = RepeatWrapping;
  roughness2.wrapT = RepeatWrapping;
  roughness2.repeat.x = 1;
  roughness2.repeat.y = -1;


  const BodyMap3 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/05.jpg"
  );
  BodyMap3.wrapS = RepeatWrapping;
  BodyMap3.wrapT = RepeatWrapping;
  BodyMap3.repeat.x = 1;
  BodyMap3.repeat.y = -1;

  const roughness3 = useLoader(
    TextureLoader,
    "./assets/textures/truck/body/05.jpg"
  );
  roughness3.wrapS = RepeatWrapping;
  roughness3.wrapT = RepeatWrapping;
  roughness3.repeat.x = 1;
  roughness3.repeat.y = -1;

  return (
    <group>
      <Truck
        position={[0, 5, -120]}
        rotation={[0, degToRad(45), 0]}
        BodyMap={BodyMap1}
        roughness={roughness1}
      />
      <Truck
        position={[-120, 5, 0]}
        rotation={[0, degToRad(45), 0]}
        BodyMap={BodyMap2}
        roughness={roughness2}
      />
      <Truck
        position={[120, 5, 0]}
        rotation={[0, degToRad(45), 0]}
        BodyMap={BodyMap3}
        roughness={roughness3}
      />
    </group>
  );
};

export default Trucks;
