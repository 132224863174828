const GlassRedMaterial = () => {
  return (
    <meshStandardMaterial
    color={0x8c0800}
    metalness={0.9}
    roughness={0}
    transparent={true}
    opacity={0.8}
    />
  )
}

export default GlassRedMaterial