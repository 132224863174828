import { DoubleSide } from "three";

const MetalicBlackMaterial = (props) => {
  // const { ChromeHdri } = props;

  return (
    <meshPhongMaterial
      color={0x000000}
      // envMap={ChromeHdri}
      reflectivity={0.9}
      shininess={90}
      specular={0x111111}
      side={DoubleSide}
    />
  );
};

export default MetalicBlackMaterial;
