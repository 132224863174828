import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

const Lights = () => {
  const spot1 = useRef();
  const targetPosition1 = [0, 20, -85];

  const spot2 = useRef();
  const targetPosition2 = [-85, 20, 0];

  const spot3 = useRef();
  const targetPosition3 = [85, 20, 0];


  useFrame(() => {
    spot1.current.target.position.set(...targetPosition1);
    spot1.current.target.updateMatrixWorld();

    spot2.current.target.position.set(...targetPosition2);
    spot2.current.target.updateMatrixWorld();

    spot3.current.target.position.set(...targetPosition3);
    spot3.current.target.updateMatrixWorld();

  });

  return (
    <group>
      <pointLight color={0x0324ff}position={[0, 30, 100]} intensity={200} castShadow/>
      <pointLight color={0x0324ff}position={[-100, 30, 0]} intensity={200} castShadow/>
      <pointLight color={0x0324ff}position={[100, 30, 0]} intensity={200} castShadow/>
      {/* <mesh position={[40, 80, -40]}>
        <meshBasicMaterial color={"red"} />
        <sphereGeometry args={[1, 1, 32, 32]} />
      </mesh>

      <mesh position={[60, 0, -60]}>
        <meshBasicMaterial color={"red"} />
        <sphereGeometry args={[1, 1, 32, 32]} />
      </mesh> */}
      <spotLight
        ref={spot1}
        position={[0, 100, -70]}
        angle={Math.PI / 8.5}
        penumbra={0.5}
        decay={1.1}
        distance={0}
        castShadow
        intensity={3000}
        // color={0x0324ff}
        color={0xFFFFFF}
      />
      <spotLight
        ref={spot2}
        position={[-70, 80, 0]}
        angle={Math.PI / 8.5}
        penumbra={0.5}
        decay={1.1}
        distance={0}
        castShadow
        intensity={3000}
        // color={0x0324ff}
        color={0xFFFFFF}
      />

      <spotLight
        ref={spot3}
        position={[70, 80, 0]}
        angle={Math.PI / 8.5}
        penumbra={0.5}
        decay={1.1}
        distance={0}
        castShadow
        intensity={3000}
        // color={0x0324ff}
        color={0xFFFFFF}
      />

      <directionalLight color={0x0324ff}intensity={2} position={[0, 20, 50]} />
      <directionalLight color={0x0324ff}intensity={2} position={[50, 20, 0]} />
      <directionalLight color={0x0324ff}intensity={2} position={[-50, 20, 0]} />

      <ambientLight intensity={1} />
    </group>
  );
};

export default Lights;
