import { DoubleSide } from "three";

const MetalicGreyMaterial = (props) => {
  // const { ChromeHdri } = props;

  return (
    <meshPhongMaterial
      color={0x3d3d3d}
      // envMap={ChromeHdri}
      reflectivity={0.5}
      shininess={40}
      specular={0x111111}
      side={DoubleSide}
    />
  );
};

export default MetalicGreyMaterial;
