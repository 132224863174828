import { useState } from "react";
import { degToRad } from "three/src/math/MathUtils";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import ImagePlane from "../../walls/ImagePlane";

const MobileWalls = () => {

    const backdrop = useLoader(
        TextureLoader,
        "./assets/textures/walls/backdrop.png"
      );

  return (
    <ImagePlane
    map={backdrop}
    position={[0, 115, -200]}
    // rotation={[0, degToRad(-45), 100]}
    width={300}
    height={275}
    color={0xffffff}
  />
  )
}

export default MobileWalls