const IronMaterial = (props) => {
  // const { ChromeHdri } = props;

  return (
    <meshPhongMaterial
      color={0x4d4d4d}
      // envMap={ChromeHdri}
      reflectivity={0.9}
      shininess={80}
      specular={0x111111}
    />
  );
};

export default IronMaterial;
