import { useEffect, useState } from "react";
import { DoubleSide } from "three";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { degToRad } from "three/src/math/MathUtils";

const Models = (props) => {
  const { model, overallIndex } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [images] = useState([
    {
      image: useLoader(TextureLoader, `${model.path}01.png`),
      position: [-170, 22.5, -170],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}02.png`),
      position: [-138, 22.5, -138],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}03.png`),
      position: [-104, 22.5, -104],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}04.png`),
      position: [-72, 22.5, -72],
      opacity: 0,
    },
    {
      image: useLoader(TextureLoader, `${model.path}05.png`),
      position: [-40, 22.5, -40],
      opacity: 0,
    },
  ]);

  const [imagesRight] = useState([
    {
      position: [170, 22.5, -170],
    },
    {
      position: [138, 22.5, -138],
    },
    {
      position: [104, 22.5, -104],
    },
    {
      position: [72, 22.5, -72],
    },
    {
      position: [40, 22.5, -40],
    },
  ]);

  useEffect(() => {
    if (model.number === overallIndex) {
      setIsActive(true);
      model.isActive = true;
    } else {
      setIsActive(false);
      model.isActive = false;
      images.forEach((image) => {
        image.opacity = 0;
      });
      setProgress(0);
      setCurrentIndex(0);
    }
  }, [overallIndex, images, model]);

  useFrame(() => {
    if (isActive) {
      if (progress < 1) {
        setProgress((prevProgress) => Math.min(prevProgress + 0.05, 1.0));
      } else {
        handleTransition();
      }
      images.forEach((image) => {
        if (image !== images[currentIndex]) {
          if (image.opacity > 0) {
            image.opacity -= 0.05;
          }
        }
        images[currentIndex].opacity = progress;
      });
    } 
  });

  const handleTransition = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setProgress(0);
    }
  };

  if(model.isActive){
    return (
      <>
        {images &&
          images.map((image, index) => {
            return (
              <mesh
                rotation={[0, degToRad(45), 0]}
                position={image.position}
                key={index}
              >
                <meshBasicMaterial
                  map={image.image}
                  side={DoubleSide}
                  transparent={true}
                  opacity={image.opacity}
                />
                <planeGeometry args={[50, 50, 50, 50]} />
              </mesh>
            );
          })}
        {imagesRight &&
          imagesRight.map((image, index) => {
            return (
              <mesh
                rotation={[0, degToRad(-45), 0]}
                position={image.position}
                key={index}
              >
                <meshBasicMaterial
                  map={images[index].image}
                  transparent={true}
                  opacity={images[index].opacity}
                />
                <planeGeometry args={[50, 50, 50, 50]} />
              </mesh>
            );
          })}
      </>
    );
  } else {
    return;
  }

};

export default Models;

// import { useEffect, useState } from "react";
// import { useFrame, useLoader } from "@react-three/fiber";
// import { TextureLoader } from "three";
// import { degToRad } from "three/src/math/MathUtils";

// const Models = (props) => {
//   const { model, overallIndex } = props;

//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [progress, setProgress] = useState(null);
//   const [isActive, setIsActive] = useState(false);
//   const [images] = useState([
//     {
//       image: useLoader(TextureLoader, `${model.path}01.png`),
//       imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//       position: [-170, 22.5, -170],
//       active: false,
//       progress: 0.0,
//     },
//     {
//       image: useLoader(TextureLoader, `${model.path}02.png`),
//       imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//       position: [-138, 22.5, -138],
//       active: false,
//       progress: 0.0,
//     },
//     {
//       image: useLoader(TextureLoader, `${model.path}03.png`),
//       imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//       position: [-104, 22.5, -104],
//       active: false,
//       progress: 0.0,
//     },
//     {
//       image: useLoader(TextureLoader, `${model.path}04.png`),
//       imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//       position: [-72, 22.5, -72],
//       active: false,
//       progress: 0.0,
//     },
//     {
//       image: useLoader(TextureLoader, `${model.path}05.png`),
//       imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//       position: [-40, 22.5, -40],
//       active: false,
//       progress: 0.0,
//     },
//   ]);

//   // const [endImage] = useState(
//   //   {
//   //     image: useLoader(TextureLoader, `${model.path}05.png`),
//   //     imageAlpha: useLoader(TextureLoader, `${model.path}06.png`),
//   //     progress: 0.0,
//   //   }
//   // );

//   const [imagesRight] = useState([
//     {
//       position: [170, 22.5, -170],
//     },
//     {
//       position: [138, 22.5, -138],
//     },
//     {
//       position: [104, 22.5, -104],
//     },
//     {
//       position: [72, 22.5, -72],
//     },
//     // {
//     //   position: [40, 22.5, -40],
//     // },
//   ]);

//   useEffect(() => {
//     if (model.number === overallIndex) {
//       setIsActive(true);
//       model.isActive = true;
//     } else {
//       setIsActive(false);
//       model.isActive = false;
//       images.forEach((image) => {
//         image.progress = 0;
//       });
//       setProgress(0);
//       setCurrentIndex(0);
//     }
//   }, [overallIndex, images, model]);

//   useFrame(() => {
//     if (isActive) {
//       if (progress < 1) {
//         setProgress((prevProgress) => Math.min(prevProgress + 0.05, 1.0));
//       } else {
//         handleTransition();
//       }
//       images.forEach((image) => {
//         if (image !== images[currentIndex]) {
//           if (image.progress > 0) {
//             image.progress -= 0.05;
//           }
//         }
//         images[currentIndex].progress = progress;
//         images[4].progress += 0.07;
//         // endImage.progress += 0.007;
//       });
//     }
//   });

//   const handleTransition = () => {
//     if (currentIndex < images.length - 1) {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) %images.length);
//       setProgress(0);
//     }
//   };

//   if (model.isActive) {
//     return (
//       <>
//         {images &&
//           images.map((image, index) => {
//             return <ShaderModel image={image} index={index} />;
//           })}
//         {/* <ShaderModelEnd
//           image={endImage.image}
//           alpha={endImage.imageAlpha}
//           position={[-40, 22.5, -40]}
//           rotation={[0, degToRad(45), 0]}
//           progress={endImage.progress}
//         /> */}

//         {imagesRight &&
//           imagesRight.map((image, index) => {
//             return (
//               <mesh
//                 rotation={[0, degToRad(-45), 0]}
//                 position={image.position}
//                 key={index}
//               >
//                 <meshBasicMaterial
//                   map={images[index].image}
//                   transparent={true}
//                   opacity={1}
//                 />
//                 <planeGeometry args={[50, 50, 50, 50]} />
//               </mesh>
//             );
//           })}
//       </>
//     );
//   } else {
//     return;
//   }
// };

// export default Models;
