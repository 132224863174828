import { useLoader } from "@react-three/fiber";
import { TextureLoader, RepeatWrapping } from "three";

const BoxConcreteMaterial = () => {

  const map = useLoader(
    TextureLoader,
    "./assets/textures/floor/concrete1.png"
  );
  const bump = useLoader(
    TextureLoader,
    "./assets/textures/floor/concrete1Bump.png"
  );
  map.wrapS = map.wrapT = RepeatWrapping;
  map.repeat.set(1, 1.8);
  bump.wrapS = bump.wrapT = RepeatWrapping;
  bump.repeat.set(1, 1.8);

  return (
    <meshStandardMaterial
      color={0xFFFFFF}
      map={map}
      bumpMap={bump}
      bumpScale={100}
    />
  );
};

export default BoxConcreteMaterial;
