import { useLoader } from "@react-three/fiber";
import { DoubleSide, TextureLoader } from "three";
const LogoOne = () => {

    const map = useLoader(
        TextureLoader,
        "./assets/textures/truck/logo-1_map.png"
      );
      const roughness = useLoader(
        TextureLoader,
        "./assets/textures/truck/logo-1_rough.png"
      );

      const alpha = useLoader(
        TextureLoader,
        "./assets/textures/truck/logo-1_alpha.png"
      );
  return (
    <mesh position={[0, 13.7, 45.2]} rotation={[0, 0, 0]}>
        <planeGeometry args={[18, 18]} />
        <meshStandardMaterial
        map={map?map:null}
        roughnessMap={roughness?roughness:null}
        alphaMap={alpha?alpha:null}
        roughness={0}
        metalness={0.9}
        transparent={true}
        side={DoubleSide}
            />
    </mesh>
  )
}

export default LogoOne

