import { useState } from "react";
import Models from "./Models";

const Talent = (props) => {
  const { overallIndex } = props;

  const [ models ] = useState([
    {
      number: 0,
      isActive: true,
      path: "./assets/talent/01/01_",
    },
    {
      number: 1,
      isActive: false,
      path: "./assets/talent/02/02_",
    },
    {
      number: 2,
      isActive: false,
      path: "./assets/talent/03/03_",
    },
    {
      number: 3,
      isActive: false,
      path: "./assets/talent/04/04_",
    },
    {
      number: 4,
      isActive: false,
      path: "./assets/talent/05/05_",
    },
    {
      number: 5,
      isActive: false,
      path: "./assets/talent/06/06_",
    },
    {
      number: 6,
      isActive: false,
      path: "./assets/talent/07/07_",
    },
    {
      number: 7,
      isActive: false,
      path: "./assets/talent/08/08_",
    },
    {
      number: 8,
      isActive: false,
      path: "./assets/talent/09/09_",
    },
    {
      number: 9,
      isActive: false,
      path: "./assets/talent/10/10_",
    },
    {
      number: 10,
      isActive: false,
      path: "./assets/talent/11/11_",
    },
    {
      number: 11,
      isActive: false,
      path: "./assets/talent/12/12_",
    },
    {
      number: 12,
      isActive: false,
      path: "./assets/talent/13/13_",
    },
    {
      number: 13,
      isActive: false,
      path: "./assets/talent/14/14_",
    },
    {
      number: 14,
      isActive: false,
      path: "./assets/talent/15/15_",
    },
    {
      number: 15,
      isActive: false,
      path: "./assets/talent/16/16_",
    },
    {
      number: 16,
      isActive: false,
      path: "./assets/talent/17/17_",
    },
    {
      number: 17,
      isActive: false,
      path: "./assets/talent/18/18_",
    },
    {
      number: 18,
      isActive: false,
      path: "./assets/talent/19/19_",
    },
    {
      number: 19,
      isActive: false,
      path: "./assets/talent/20/20_",
    },
    {
      number: 20,
      isActive: false,
      path: "./assets/talent/21/21_",
    },
    {
      number: 21,
      isActive: false,
      path: "./assets/talent/22/22_",
    },
    {
      number: 22,
      isActive: false,
      path: "./assets/talent/23/23_",
    },
    {
      number: 23,
      isActive: false,
      path: "./assets/talent/24/24_",
    },
    {
      number: 24,
      isActive: false,
      path: "./assets/talent/25/25_",
    },
    {
      number: 25,
      isActive: false,
      path: "./assets/talent/26/26_",
    },
    {
      number: 26,
      isActive: false,
      path: "./assets/talent/27/27_",
    },
    {
      number: 27,
      isActive: false,
      path: "./assets/talent/28/28_",
    },
    {
      number: 28,
      isActive: false,
      path: "./assets/talent/29/29_",
    },
    {
      number: 29,
      isActive: false,
      path: "./assets/talent/30/30_",
    },
    {
      number: 30,
      isActive: false,
      path: "./assets/talent/31/31_",
    },
    {
      number: 31,
      isActive: false,
      path: "./assets/talent/32/32_",
    },
    {
      number: 32,
      isActive: false,
      path: "./assets/talent/33/33_",
    },
    {
      number: 33,
      isActive: false,
      path: "./assets/talent/34/34_",
    },
  ]);

  // const [models1] = useState([
  //   {
  //     number: 0,
  //     isActive: false,
  //     path: "./assets/talent/01/01_",
  //   },
  //   {
  //     number: 1,
  //     isActive: false,
  //     path: "./assets/talent/02/02_",
  //   },
  //   {
  //     number: 2,
  //     isActive: false,
  //     path: "./assets/talent/03/03_",
  //   },
  //   {
  //     number: 3,
  //     isActive: false,
  //     path: "./assets/talent/04/04_",
  //   },
  //   {
  //     number: 4,
  //     isActive: false,
  //     path: "./assets/talent/05/05_",
  //   },
  // ]);

  // const [models2] = useState([
  //   {
  //     number: 5,
  //     isActive: false,
  //     path: "./assets/talent/06/06_",
  //   },
  //   {
  //     number: 6,
  //     isActive: false,
  //     path: "./assets/talent/07/07_",
  //   },
  //   {
  //     number: 7,
  //     isActive: false,
  //     path: "./assets/talent/08/08_",
  //   },
  //   {
  //     number: 8,
  //     isActive: false,
  //     path: "./assets/talent/09/09_",
  //   },
  //   {
  //     number: 9,
  //     isActive: false,
  //     path: "./assets/talent/10/10_",
  //   },
  // ]);

  // const [models3] = useState([
  //   {
  //     number: 10,
  //     isActive: false,
  //     path: "./assets/talent/11/11_",
  //   },
  //   {
  //     number: 11,
  //     isActive: false,
  //     path: "./assets/talent/12/12_",
  //   },
  //   {
  //     number: 12,
  //     isActive: false,
  //     path: "./assets/talent/13/13_",
  //   },
  //   {
  //     number: 13,
  //     isActive: false,
  //     path: "./assets/talent/14/14_",
  //   },
  //   {
  //     number: 14,
  //     isActive: false,
  //     path: "./assets/talent/15/15_",
  //   },
  // ]);

  // const [models4] = useState([
  //   {
  //     number: 15,
  //     isActive: false,
  //     path: "./assets/talent/16/16_",
  //   },
  //   {
  //     number: 16,
  //     isActive: false,
  //     path: "./assets/talent/17/17_",
  //   },
  //   {
  //     number: 17,
  //     isActive: false,
  //     path: "./assets/talent/18/18_",
  //   },
  //   {
  //     number: 18,
  //     isActive: false,
  //     path: "./assets/talent/19/19_",
  //   },
  //   {
  //     number: 19,
  //     isActive: false,
  //     path: "./assets/talent/20/20_",
  //   },
  // ]);

  // const [models5] = useState([
  //   {
  //     number: 20,
  //     isActive: false,
  //     path: "./assets/talent/21/21_",
  //   },
  //   {
  //     number: 21,
  //     isActive: false,
  //     path: "./assets/talent/22/22_",
  //   },
  //   {
  //     number: 22,
  //     isActive: false,
  //     path: "./assets/talent/23/23_",
  //   },
  //   {
  //     number: 23,
  //     isActive: false,
  //     path: "./assets/talent/24/24_",
  //   },
  //   {
  //     number: 24,
  //     isActive: false,
  //     path: "./assets/talent/25/25_",
  //   },
  // ]);

  // const [models6] = useState([
  //   {
  //     number: 25,
  //     isActive: false,
  //     path: "./assets/talent/26/26_",
  //   },
  //   {
  //     number: 26,
  //     isActive: false,
  //     path: "./assets/talent/27/27_",
  //   },
  //   {
  //     number: 27,
  //     isActive: false,
  //     path: "./assets/talent/28/28_",
  //   },
  //   {
  //     number: 28,
  //     isActive: false,
  //     path: "./assets/talent/29/29_",
  //   },
  //   {
  //     number: 29,
  //     isActive: false,
  //     path: "./assets/talent/30/30_",
  //   },
  // ]);

  // const [models7] = useState([
  //   {
  //     number: 30,
  //     isActive: false,
  //     path: "./assets/talent/31/31_",
  //   },
  //   {
  //     number: 31,
  //     isActive: false,
  //     path: "./assets/talent/32/32_",
  //   },
  //   {
  //     number: 32,
  //     isActive: false,
  //     path: "./assets/talent/33/33_",
  //   },
  //   {
  //     number: 33,
  //     isActive: false,
  //     path: "./assets/talent/34/34_",
  //   },
  // ]);

  // useEffect(() => {
  //   console.log(overallIndex, models);
  //   if (overallIndex < 4) {
  //     setModels([...models1]);
  //   } else if (overallIndex >= 4 && overallIndex < 9) {
  //     setModels([...models2]);
  //   } else if (overallIndex >= 9 && overallIndex < 14) {
  //     setModels([...models3]);
  //   } else if (overallIndex >= 14 && overallIndex < 19) {
  //     setModels([...models4]);
  //   } else if (overallIndex >= 19 && overallIndex < 24) {
  //     setModels([...models5]);
  //   } else if (overallIndex >= 24 && overallIndex < 29) {
  //     setModels([...models6]);
  //   } else if (overallIndex >= 29) {
  //     setModels([...models7]);
  //   }

  //   const newModels = [...models];
  //   for (let i = 0; i < newModels.length; i++) {
  //     newModels[i].isActive = false;
  //   }
  //   newModels[lookIndex].isActive = true;
  //   setModels(newModels);
  // }, [
  //   setModels,
  //   overallIndex,
  //   lookIndex,
  //   // models,
  //   models1,
  //   models2,
  //   models3,
  //   models4,
  //   models5,
  //   models6,
  //   models7,
  // ]);

  // useEffect(() => {

  //   let newModels;
  //   // console.log(lookIndex);
  //   if(overallIndex < 4){
  //     newModels = [...models1];
  //   } else if (overallIndex >= 4 && overallIndex < 9){
  //     newModels = [...models2];
  //   } else if (overallIndex >= 9 && overallIndex < 14){
  //     newModels = [...models3];
  //   } else if (overallIndex >= 14 && overallIndex < 19){
  //     newModels = [...models4];
  //   } else if (overallIndex >= 19 && overallIndex < 24){
  //     newModels = [...models5];
  //   } else if (overallIndex >= 24 && overallIndex < 29){
  //     newModels = [...models6];
  //   } else if (overallIndex >= 29){
  //     newModels = [...models7];
  //   }

  //   for (let i = 0; i < newModels.length; i++) {
  //     newModels[i].isActive = false;
  //   }

  //   newModels[lookIndex].isActive = true;
  //   setModels(newModels);
  // }, [models, overallIndex, lookIndex]);

  //   const [emptyTest] = useState([
  //   {},
  //   {},
  //   {},
  //   {},
  //   {},
  // ]);

  // useEffect(() => {
  //   let newModels = null;
  //   if(overallIndex < 5){
  //     newModels = [...models1];
  //   } else if (overallIndex >= 5 && overallIndex < 10){
  //     newModels = [...models2];
  //   } else if (overallIndex >= 10 && overallIndex < 15){
  //     newModels = [...models1, ...models2, ...models3];
  //   } else if (overallIndex >= 15 && overallIndex < 20){
  //     newModels = [...models1, ...models2, ...models3, ...models4];
  //   } else if (overallIndex >= 20 && overallIndex < 25){
  //     newModels = [...models1, ...models2, ...models3, ...models4, ...models5];
  //   } else if (overallIndex >= 25 && overallIndex < 30){
  //     newModels = [...models1, ...models2, ...models3, ...models4, ...models5, ...models6];
  //   } else if (overallIndex >= 30){
  //     newModels = [...models1, ...models2, ...models3, ...models4, ...models5, ...models6, ...models7];
  //   }
  //     for (let i = 0; i < newModels.length; i++) {
  //       newModels[i].isActive = false;
  //     }
  //     // console.log(newModels);

  //   newModels[lookIndex].isActive = true;
  //   setModels(newModels);
  // });

  // useEffect(() => {
  //   let newModels = null;
  //   if(overallIndex < 5){
  //     newModels = [...models1];
  //   } else if (overallIndex >= 5 && overallIndex < 10){
  //     newModels = [...models1, ...models2];
  //   } else if (overallIndex >= 10 && overallIndex < 15){
  //     newModels = [...emptyTest, ...models2, ...models3];
  //   } else if (overallIndex >= 15 && overallIndex < 20){
  //     newModels = [...emptyTest, ...models2, ...models3, ...models4];
  //   } else if (overallIndex >= 20 && overallIndex < 25){
  //     newModels = [...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...models5];
  //   } else if (overallIndex >= 25 && overallIndex < 30){
  //     newModels = [...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...models6];
  //   } else if (overallIndex >= 30){
  //     newModels = [...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...emptyTest, ...models7];
  //   }
  //     for (let i = 0; i < newModels.length; i++) {
  //       newModels[i].isActive = false;
  //     }
  //     // console.log(overallIndex);

  //   newModels[overallIndex].isActive = true;
  //   setModels(newModels);
  // }, [ overallIndex]);

  // useEffect(() => {
  //   console.log(models);
  //   const newModels = [...models];
  //   newModels[lookIndex].isActive = true;
  // //   // for (let i = 0; i < newModels.length; i++) {
  // //   //   newModels[i].isActive = false;
  // //   // }
  // //   newModels[lookIndex].isActive = true;
  //   setModels(newModels);

  // }, [models]);

  return (
    <>
      {models &&
        models.map((model, index) => {
          // if (model.number === overallIndex) {
            return (
              <Models
                model={model}
                key={index}
                index={index}
                overallIndex={overallIndex}
              />
            );
          // }
        })}
    </>
  );

  // if()
  // useEffect(()=>{
  //   console.log(overallIndex);
  // },[overallIndex])

  // if (overallIndex < 5) {
  //   return (
  //     <>
  //       {models1 &&
  //         models1.map((model, index) => {
  //           return (
  //             <Models
  //               model={model}
  //               key={index}
  //               index={index}
  //               lookIndex={lookIndex}
  //               overallIndex={overallIndex}
  //             />
  //           );
  //         })}
  //     </>
  //   );
  // } else if (overallIndex >= 5 && overallIndex < 10) {
  //   return (
  //     <>
  //     {models2 &&
  //       models2.map((model, index) => {
  //         return (
  //           <Models
  //             model={model}
  //             key={index}
  //             index={index}
  //             lookIndex={lookIndex}
  //             overallIndex={overallIndex}
  //           />
  //         );
  //       })}
  //   </>
  //   )
  // } else {
  //   return;
  // }

  // if (overallIndex >= 9 && overallIndex < 14) {
  //   setModels([...models3]);
  // } else if (overallIndex >= 14 && overallIndex < 19) {
  //   setModels([...models4]);
  // } else if (overallIndex >= 19 && overallIndex < 24) {
  //   setModels([...models5]);
  // } else if (overallIndex >= 24 && overallIndex < 29) {
  //   setModels([...models6]);
  // } else if (overallIndex >= 29) {
  //   setModels([...models7]);
  // }
};

export default Talent;
