import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

const CylinderConcreteMaterial = () => {

  const map = useLoader(
    TextureLoader,
    "./assets/textures/floor/concrete2.png"
  );
  const bump = useLoader(
    TextureLoader,
    "./assets/textures/floor/concrete2Bump.png"
  );

  return (
    <meshStandardMaterial
      color={0xFFFFFF}
      map={map}
      bumpMap={bump}
      bumpScale={50}
    />
  );
};

export default CylinderConcreteMaterial;
