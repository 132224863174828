import { DoubleSide } from "three";

const RubberMaterial = (props) => {
  const { BodyMap, RubberGloss } = props;
  return (
    <meshStandardMaterial
      map={BodyMap}
      roughnessMap={RubberGloss}
      metalnessMap={RubberGloss}
      roughness={0.7}
      metalness={0.7}
      side={DoubleSide}
    />
  );
};

export default RubberMaterial;
