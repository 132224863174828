import { useState } from "react";
import MobileModels from "./MobileModels";

const MobileTalent = (props) => {
  const { overallIndex } = props;

    const [ models ] = useState([
      {
        number: 0,
        isActive: true,
        path: "./assets/talent/01/01_",
      },
      {
        number: 1,
        isActive: false,
        path: "./assets/talent/02/02_",
      },
      {
        number: 2,
        isActive: false,
        path: "./assets/talent/03/03_",
      },
      {
        number: 3,
        isActive: false,
        path: "./assets/talent/04/04_",
      },
      {
        number: 4,
        isActive: false,
        path: "./assets/talent/05/05_",
      },
      {
        number: 5,
        isActive: false,
        path: "./assets/talent/06/06_",
      },
      {
        number: 6,
        isActive: false,
        path: "./assets/talent/07/07_",
      },
      {
        number: 7,
        isActive: false,
        path: "./assets/talent/08/08_",
      },
      {
        number: 8,
        isActive: false,
        path: "./assets/talent/09/09_",
      },
      {
        number: 9,
        isActive: false,
        path: "./assets/talent/10/10_",
      },
      {
        number: 10,
        isActive: false,
        path: "./assets/talent/11/11_",
      },
      {
        number: 11,
        isActive: false,
        path: "./assets/talent/12/12_",
      },
      {
        number: 12,
        isActive: false,
        path: "./assets/talent/13/13_",
      },
      {
        number: 13,
        isActive: false,
        path: "./assets/talent/14/14_",
      },
      {
        number: 14,
        isActive: false,
        path: "./assets/talent/15/15_",
      },
      {
        number: 15,
        isActive: false,
        path: "./assets/talent/16/16_",
      },
      {
        number: 16,
        isActive: false,
        path: "./assets/talent/17/17_",
      },
      {
        number: 17,
        isActive: false,
        path: "./assets/talent/18/18_",
      },
      {
        number: 18,
        isActive: false,
        path: "./assets/talent/19/19_",
      },
      {
        number: 19,
        isActive: false,
        path: "./assets/talent/20/20_",
      },
      {
        number: 20,
        isActive: false,
        path: "./assets/talent/21/21_",
      },
      {
        number: 21,
        isActive: false,
        path: "./assets/talent/22/22_",
      },
      {
        number: 22,
        isActive: false,
        path: "./assets/talent/23/23_",
      },
      {
        number: 23,
        isActive: false,
        path: "./assets/talent/24/24_",
      },
      {
        number: 24,
        isActive: false,
        path: "./assets/talent/25/25_",
      },
      {
        number: 25,
        isActive: false,
        path: "./assets/talent/26/26_",
      },
      {
        number: 26,
        isActive: false,
        path: "./assets/talent/27/27_",
      },
      {
        number: 27,
        isActive: false,
        path: "./assets/talent/28/28_",
      },
      {
        number: 28,
        isActive: false,
        path: "./assets/talent/29/29_",
      },
      {
        number: 29,
        isActive: false,
        path: "./assets/talent/30/30_",
      },
      {
        number: 30,
        isActive: false,
        path: "./assets/talent/31/31_",
      },
      {
        number: 31,
        isActive: false,
        path: "./assets/talent/32/32_",
      },
      {
        number: 32,
        isActive: false,
        path: "./assets/talent/33/33_",
      },
      {
        number: 33,
        isActive: false,
        path: "./assets/talent/34/34_",
      },
    ]);


      return (
        <>
          {models &&
            models.map((model, index) => {
                return (
                  <MobileModels
                    model={model}
                    key={index}
                    index={index}
                    overallIndex={overallIndex}
                  />
                );
            })}
        </>
      );
}

export default MobileTalent