import { degToRad } from "three/src/math/MathUtils";
import BoxConcreteMaterial from "../../floor/BoxConcreteMaterial";
import CylinderConcreteMaterial from "../../floor/CylinderConcreteMaterial";

const MobileFloor = () => {
  return (
    <>
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -20, 0]}
        castShadow
        receiveShadow
      >
        <meshStandardMaterial
          color={0x111111}
          roughness={0.1}
          metalness={0.9}
        />
        <planeGeometry args={[400, 400]} />
      </mesh>
      <mesh
        // rotation={[0, degToRad(135), 0]}
        position={[0, -5, -90]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[35, 5, 225]} />
        <BoxConcreteMaterial />
      </mesh>
      <mesh
        rotation={[0, 0, 0]}
        position={[0, -10, 5]}
        castShadow
        receiveShadow
      >
        <cylinderGeometry args={[30, 30, 20, 64]} />
        <CylinderConcreteMaterial />
      </mesh>
    </>
  );
};

export default MobileFloor;
