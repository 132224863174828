import { Link } from "react-router-dom";
import "./Header.scss";

const Header = (props) => {
  const { 
    audio, 
    isMuted, 
    setIsMuted,
    overallIndex, 
    setOverallIndex,
  } = props;

  const handleMute = () => {
    if (isMuted) {
      setIsMuted(false);
      audio.current.play();
    } else {
      setIsMuted(true);
      audio.current.pause();
    }
  };

  const handlePrev = () => {
    setOverallIndex((oldIndex) => oldIndex - 1);
  };

  const handleNext = () => {
    setOverallIndex((oldIndex) => oldIndex + 1);
  };

  return (
    <>
      <img
        src="./assets/images/logo.png"
        alt="MASHA POPOVA"
        className="header__logo"
      />
      <button className="header__mute default-button" onClick={handleMute}>
        {isMuted ? "UNMUTE" : "MUTE"}
      </button>
      <audio
        src="./assets/audio/TruckSounds.mp3"
        loop={true}
        ref={audio}
        style={{ display: "none" }}
      />

      <nav className="header__nav-outer">
        <Link to={"/lookbook"} className="default-button header__nav-item">
          LOOKS
        </Link>
        <Link to={"/zine"} className="default-button header__nav-item">
          ZINE
        </Link>
        <a
          href="https://www.instagram.com/mashapopovap/"
          className="default-button header__nav-item"
          target="_blank"
          rel="noreferrer"
        >
          INSTA
        </a>
        <Link to={"/credits"} className="default-button header__nav-item">
          CREDITS
        </Link>
      </nav>

      <button
        onClick={handlePrev}
        className={`header__prev default-button ${
          overallIndex === 0 ? "disabled" : ""
        }`}
      >
        &larr; PREV LOOK
      </button>
      <button
        onClick={handleNext}
        className={`header__next default-button ${
          overallIndex === 33 ? "disabled" : ""
        }`}
      >
        NEXT LOOK &rarr;
      </button>

    </>
  );
};

export default Header;
