import { DoubleSide } from "three";

const ChromeMaterial = (props) => {
  // const { ChromeHdri } = props;

  return (
    <meshPhongMaterial
      color={0xAAAAAA}
      // envMap={ChromeHdri}
      reflectivity={1}
      shininess={100}
      specular={0xFFFFFF}
      side={DoubleSide}
    />
  );
};

export default ChromeMaterial;
