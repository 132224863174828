const GlassOrangeMaterial = () => {
  return (
    <meshStandardMaterial
    color={0x7d3a02}
    transparent={true}
    opacity={0.8}
    />
  )
}

export default GlassOrangeMaterial